import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Auth from 'layouts/Auth';
import {
	getWalletsBalance,
	getWalletsCryptoList,
	getWalletsFiatList,
} from 'redux/reducers/wallets/selectors';
import { getWalletsRequest } from 'redux/reducers/wallets/reducer';
import { currencyDataRequest } from 'redux/reducers/currency/reducer';
import { EWalletType } from 'redux/reducers/wallets/types';
import { roundingNumber } from 'services/utils/roundingNumber';
import WalletsTable from 'components/Wallets/WalletsTable/WalletsTable';
import WalletSideBar from 'components/Wallets/WalletSideBar/WalletSideBar';
import { navList } from 'routes/routesList';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { motion } from 'framer-motion/dist/framer-motion';

const WalletsPage = () => {
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(getWalletsRequest());
		dispatch(currencyDataRequest());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const walletsCryptoList = useSelector(getWalletsCryptoList);
	const walletsFiatList = useSelector(getWalletsFiatList);
	const walletsBalance = useSelector(getWalletsBalance);

	return (
		<Auth>
			<section className="wallet-section">
				<div className="page-wrap">
					<WalletSideBar />
					<motion.div
						className="wallet-content"
						initial={{ opacity: 0 }}
						animate={{ opacity: 1, transition: { duration: 0.4 } }}
						exit={{ opacity: 0, transition: { duration: 0.4 } }}
					>
						<div className="wallet-content-header">
							<div className="wallet-content-header__title">
								<p>Wallets</p>
							</div>
						</div>
						<div className="wallet-content-balance flex">
							<div className="wallet-content-balance-item">
								<div className="wallet-content-balance-item__title">
									<p>Total Balance</p>
								</div>
								<div className="wallet-content-balance-item__value">
									<p>
										{roundingNumber(
											Number(
												(Number(walletsBalance?.crypto.btc) + Number(walletsBalance?.fiat.btc))
													.toFixed(6)
													.slice(0, -1),
											) > 0
												? Number(walletsBalance?.crypto.eur) + Number(walletsBalance?.fiat.eur)
												: 0,
											'EUR',
										)}
										<span>EUR</span>
									</p>
								</div>
								<div className="wallet-content-balance-item__user-currensy">
									<p>
										≈{' '}
										{roundingNumber(
											Number(walletsBalance?.crypto.btc) + Number(walletsBalance?.fiat.btc),
											'BTC',
										)}{' '}
										BTC
									</p>
								</div>
							</div>
							<div className="wallet-content-balance-item">
								<div className="wallet-content-balance-item__title">
									<p>Crypto Balance</p>
								</div>
								<div className="wallet-content-balance-item__value">
									<p>
										{roundingNumber(
											Number(walletsBalance?.crypto.btc.toFixed(6).slice(0, -1)) > 0
												? walletsBalance?.crypto.eur
												: 0,
											'EUR',
										)}{' '}
										<span>EUR</span>
									</p>
								</div>
								<div className="wallet-content-balance-item__user-currensy">
									<p>≈ {roundingNumber(walletsBalance?.crypto.btc, 'BTC')} BTC</p>
								</div>
							</div>
							<div className="wallet-content-balance-item">
								<div className="wallet-content-balance-item__title">
									<p>Fiat Balance</p>
								</div>
								<div className="wallet-content-balance-item__value">
									<p>
										{roundingNumber(
											Number(walletsBalance?.fiat.btc.toFixed(6).slice(0, -1)) > 0
												? walletsBalance?.fiat.eur
												: 0,
											'EUR',
										)}{' '}
										<span>EUR</span>
									</p>
								</div>
								<div className="wallet-content-balance-item__user-currensy">
									<p>≈ {roundingNumber(walletsBalance?.fiat.btc, 'BTC')} BTC</p>
								</div>
							</div>
						</div>
						<div className="wallet-content-tables">
							<WalletsTable
								title="Fiat Balances"
								walletsList={walletsFiatList}
								itemsPerPage={4}
								type={EWalletType.FIAT}
							/>

							<WalletsTable
								itemsPerPage={4}
								title="Crypto Balances"
								walletsList={walletsCryptoList}
								type={EWalletType.CRYPTO}
							/>
						</div>
					</motion.div>
				</div>
			</section>
		</Auth>
	);
};

export default WalletsPage;
