import React from 'react';
import Logo from 'assets/img/sidebar_logo_gray.svg';

import { Link } from 'react-router-dom';
import { useHistory } from 'react-router';
import { ROUTES } from 'routes/constants';

const Header = () => {
	const history = useHistory();
	return (
		<>
			<Link to="/login" className="header__logo">
				<img alt="Logo" src={Logo} />
			</Link>
			{history.location.pathname !== '/login' &&
				history.location.pathname !== '/reset-password' &&
				history.location.pathname !== '/confirmation' &&
				history.location.pathname !== '/registration/twoFa/' &&
				history.location.pathname !== ROUTES.CREATE_PASSWORD && (
					<div className="header__nav">
						<Link to="/login" type="button" className="btn btn-outline btn--small">
							Log In
						</Link>
					</div>
				)}
		</>
	);
};

export default Header;
