import React, { useCallback, useEffect, useState } from 'react';
import Auth from 'layouts/Auth';
import BankAccountsList from 'components/PaymentMethods/PaymentMethodsList/BankAccountsList/BankAccountsList';
import WalletAddressesList from 'components/PaymentMethods/PaymentMethodsList/WalletAddressesList/WalletAddressesList';
import WalletSideBar from 'components/Wallets/WalletSideBar/WalletSideBar';
import BankAccountForm from 'components/PaymentMethods/BankAccountForm/BankAccountForm';
import WalletAddressForm from 'components/PaymentMethods/WalletAddressForm/WalletAddressForm';
import PaymentMethodsHeader from 'components/PaymentMethods/PaymentMethodsHeader/PaymentMethodsHeader';
import AddWalletAddress from 'layouts-elements/PopUp/AddWalletAddress/AddWalletAddress';
import { currencyDataRequest } from 'redux/reducers/currency/reducer';
import { getWalletsRequest } from 'redux/reducers/wallets/reducer';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { motion } from 'framer-motion/dist/framer-motion';
import { navList } from '../../../routes/routesList';

const PaymentMethodsPage = () => {
	const dispatch = useDispatch();
	const [bankAccountFormOpen, setBankAccountFormOpen] = useState(false);
	const [walletAddressFormOpen, setWalletAddressFormOpen] = useState(false);

	const [bankAccountId, setBankAccountId] = useState<number | null>(null);
	const [walletAddressId, setWalletAddressId] = useState<number | null>(null);

	const [openModalAddAddress, setOpenModalAddAddress] = useState(false);

	const closeModalAddAddress = () => {
		document.documentElement.style.overflowY = 'auto';
		setOpenModalAddAddress(false);
		setWalletAddressId(null);
	};

	useEffect(() => {
		dispatch(currencyDataRequest());
		dispatch(getWalletsRequest());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const openAddBankAccountForm = () => {
		setBankAccountFormOpen(true);
	};
	const openUpdateBankAccountForm = (id: number) => {
		setBankAccountId(id);
		setBankAccountFormOpen(true);
	};
	const closeBankAccountForm = () => {
		setBankAccountId(null);
		setBankAccountFormOpen(false);
	};

	const openAddWalletAddressForm = () => {
		// document.documentElement.style.overflowY = 'hidden';
		// setOpenModalAddAddress(true); // opens popup
		window.scrollTo(0, 0);
		setWalletAddressFormOpen(true); // opens form on separate page
	};
	const openUpdateWalletAddressForm = (id: number) => {
		setWalletAddressId(id);
		setWalletAddressFormOpen(true); // opens form on separate page
		// setOpenModalAddAddress(true); // opens popup
	};
	const closeWalletAddressForm = () => {
		setWalletAddressId(null);
		setWalletAddressFormOpen(false);
	};

	return (
		<Auth>
			<section className="wallet-section">
				<div className="page-wrap">
					<WalletSideBar />
					<motion.div
						className="wallet-operations"
						initial={{ opacity: 0 }}
						animate={{ opacity: 1, transition: { duration: 0.4 } }}
						exit={{ opacity: 0, transition: { duration: 0.4 } }}
					>
						{/* <div className="wallet-content-header wallet-content-header--mb-40">
							<div className="wallet-content-header__title">
								<p>Beneficiaries</p>
							</div>
							<div className="wallet-content-header__buttons flex flex-e flex-m">
								<Link to={navList.trade.path} className="btn btn-primary">
									Trade
								</Link>
								<Link
									to={navList.depositFiat.path}
									className="btn btn-primary btn-primary--blue300"
								>
									Deposit
								</Link>
								<Link
									to={navList.withdrawFiat.path}
									className="btn btn-primary btn-primary--blue100"
								>
									Withdraw
								</Link>
							</div>
						</div> */}
						{!bankAccountFormOpen && !walletAddressFormOpen ? (
							<>
								<PaymentMethodsHeader
									title="Bank Accounts"
									buttonText="Add Bank Account"
									buttonHandler={openAddBankAccountForm}
								/>
								<BankAccountsList onUpdate={openUpdateBankAccountForm} />

								<PaymentMethodsHeader
									title="Wallet Addresses"
									buttonText="Add Wallet Address"
									buttonHandler={openAddWalletAddressForm}
								/>
								<WalletAddressesList onUpdate={openUpdateWalletAddressForm} />
							</>
						) : (
							<>
								{bankAccountFormOpen && (
									<BankAccountForm closeForm={closeBankAccountForm} id={bankAccountId} />
								)}
								{walletAddressFormOpen && (
									<WalletAddressForm closeForm={closeWalletAddressForm} id={walletAddressId} />
								)}
							</>
						)}
						{openModalAddAddress && (
							<AddWalletAddress
								open={openModalAddAddress}
								closeModal={closeModalAddAddress}
								walletId={walletAddressId || 0}
							/>
						)}
					</motion.div>
				</div>
			</section>
		</Auth>
	);
};

export default PaymentMethodsPage;
