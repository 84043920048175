import { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { getErrorMsg } from 'redux/reducers/errors/selectors';
import { roundingNumber } from 'services/utils/roundingNumber';
import { IAdminFormConrol } from './types';

// ==========================================:
const FormControl: FC<IAdminFormConrol> = (props) => {
	const {
		form,
		field,
		isShowPass,
		setIsShowPass,
		title,
		children,
		ariaLabel,
		className,
		addonRight,
		buttonMax,
		link,
		linkTitle,
	} = props;

	const errorMsg = useSelector(getErrorMsg);
	const errors: string = form?.errors?.[field?.name];
	const touched: string = form?.touched?.[field.name];
	const inputClassName: string = className || '';

	const handlePassDisplay = (): void => {
		if (setIsShowPass) {
			setIsShowPass(!isShowPass);
		}
	};

	let inputStatus = '';

	if (errors && touched) {
		inputStatus = 'input--error';
	} else if (!errors && touched) {
		inputStatus = 'input--success';
	} else {
		inputStatus = '';
	}

	const handleMaxButton = () => {
		field.onChange({
			target: {
				// value: roundingNumber(buttonMax, addonRight || 'crypto').replace(/,/, ''),
				value: roundingNumber(buttonMax, addonRight || 'crypto').replace(/,/g, ''),
				name: field.name,
			},
		});
		// console.log(roundingNumber(buttonMax, addonRight || 'crypto').replace(/,/g, ''));
	};

	useEffect(() => {
		if (field && form) {
			if (field.name === 'password' && errorMsg === 'invalid_credentials') {
				form.setFieldError('password', 'Your password is incorrect.');
			} else if (field.name === 'totp' && errorMsg === 'invalid_totp_code') {
				form.setFieldError('totp', 'Your 2FA code is incorrect.');
			} else if (field.name === 'totp' && errorMsg === 'Invalid Authentication Code.') {
				form.setFieldError('totp', 'Your 2FA code is incorrect.');
			} else if (field.name === 'totp' && errorMsg === 'the_totp_field_is_required') {
				form.setFieldError('totp', 'Please enter 2FA code.');
			} else if (field.name === 'digit_code' && errorMsg === 'invalid_totp_code') {
				form.setFieldError('digit_code', 'Your 2FA code is incorrect.');
			} else if (field.name === 'digit_code' && errorMsg === 'Invalid Authentication Code.') {
				form.setFieldError('digit_code', 'Your 2FA code is incorrect.');
			} else if (field.name === 'email' && errorMsg === 'invalid_email') {
				form.setFieldError('email', 'No account is associated with this email.');
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [errorMsg]);

	return (
		<div className={`input ${inputStatus} ${inputClassName}`}>
			<label htmlFor={title}>
				{title && <p className="input__name">{title}</p>}
				<div className={`${addonRight ? 'input-double-item addon-right' : ''}`}>
					<div className={`input-wrapper ${errors && touched ? 'input--error' : ''}`}>
						{children}
						<span className="input-icon input-icon--more-right input-icon--success icon-Checkbox" />
						<span className="input-icon input-icon--more-right input-icon--error icon-close" />
						{setIsShowPass && (
							<button
								type="button"
								onClick={handlePassDisplay}
								aria-label={ariaLabel}
								className="show-pass"
							>
								<span className="password-type icon-eye" />
								<span className="text-type icon-eye2" />
							</button>
						)}
						<div className="input-item-text">
							{addonRight && buttonMax && buttonMax !== 0 ? (
								<button
									type="button"
									className="button input-item-text--fz-16 button--type-text"
									onClick={handleMaxButton}
								>
									Max
								</button>
							) : null}
							{addonRight && buttonMax === 0 && (
								<button type="button" className="button input-item-text--fz-16 button--type-text">
									Max
								</button>
							)}
						</div>
					</div>
					{addonRight && <div className="input-item-info info-right">{addonRight}</div>}
				</div>
				{(!!link || (errors && touched)) && (
					<div className="input-notify">
						<div className="input__notification input__notification--type2">
							<p>{errors && touched && errors}</p>
							{link && <Link to={link}>{linkTitle}</Link>}
						</div>
					</div>
				)}
			</label>
		</div>
	);
};

export default FormControl;
