import { createSelector } from '@reduxjs/toolkit';
import { IStoreState } from '../../types';
import { ICurrencyStore } from './types';

// ==========================================:
const getAuthState = (state: IStoreState): ICurrencyStore => state.currency;
export const getCurrencyData = createSelector(
	[getAuthState],
	(currency: ICurrencyStore) => currency.data,
);

export const getFiatCurrencyData = createSelector([getAuthState], (currency: ICurrencyStore) => {
	return currency.data.filter((el) => el.type === 'fiat');
});

export const getCryptoCurrencyData = createSelector([getAuthState], (currency: ICurrencyStore) => {
	return currency.data.filter((el) => el.type !== 'fiat');
});

export const getFees = (state: IStoreState) => {
	return getAuthState(state).fees;
};
