import React, { useState } from 'react';
import logo from 'assets/dist/img/logo.svg';
import userAvatar from 'assets/dist/img/user-avatar.svg';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { logoutRequest } from 'redux/reducers/auth/reducer';
import { getUserData } from 'redux/reducers/auth/selectors';
import WalletSideBar from 'components/Wallets/WalletSideBar/WalletSideBar';
import { navList } from '../../../routes/routesList';

const HeaderLogin = () => {
	const dispatch = useDispatch();
	const [profileMenu, setProfileMenu] = useState(false);
	const history = useHistory();
	const user = useSelector(getUserData);
	const isUnverifiedUserStatus =
		user?.status?.name !== 'unverified' && user?.status?.name !== 'pending';
	return (
		<>
			<WalletSideBar />
		</>
	);
};
export default HeaderLogin;
