import { FC } from 'react';
import Popup from 'reactjs-popup';
import { useSelector } from 'react-redux';
import { getInvoiceData, getInvoiceFiles } from 'redux/reducers/transactions/selectors';
import { roundingNumber } from 'services/utils/roundingNumber';

import { IPopUp } from '../types';

export interface ITransactionProps {
	currencyCode: string;
}

const InvoiceGenerate: FC<IPopUp & ITransactionProps> = ({ open, closeModal, currencyCode }) => {
	const invoiceFiles = useSelector(getInvoiceFiles);
	const invoiceData = useSelector(getInvoiceData);
	return (
		<Popup open={open} onClose={closeModal}>
			<div className="modal">
				<div className="popup popup--width-660">
					<div className="popup__close-btn" onClick={closeModal} />
					<div className="popup-header">
						<p className="popup-header__title">Deposit Instructions</p>
					</div>
					<div className="popup-body">
						<div className="invoice">
							{/* <div className="invoice-header">
								<p>TRANSFER FUNDS TO BANK ACCOUNT BELOW</p>
							</div> */}
							<div className="invoice-list">
								<div className="invoice-list__item">
									<p>Deposit Amount:</p>
									<span>
										{roundingNumber(invoiceData?.amount)} {currencyCode}
									</span>
								</div>
								<div className="invoice-list__item">
									<p>Deposit Fee:</p>
									<span>
										{roundingNumber(invoiceData?.fee)} {currencyCode}
									</span>
								</div>
								<div className="invoice-list__item">
									<p>Currency:</p>
									<span>{currencyCode}</span>
								</div>
								{/* <div className="invoice-list__item">
									<p>Deposit Method:</p>
									<span>{invoiceData?.deposit_method}</span>
								</div> */}
								{/* <div className="invoice-list__item invoice-list__item--type2">
									<p>Account Holder:</p>
									<span>{invoiceData?.account_holder}</span>
								</div> */}
								<div className="invoice-list__line" />
								<div className="invoice-list__item">
									<p>Beneficiary Name:</p>
									<span>{invoiceData?.beneficiary_name}</span>
								</div>
								<div className="invoice-list__item">
									<p>Beneficiary Address:</p>
									<span>{invoiceData?.beneficiary_address}</span>
								</div>
								<div className="invoice-list__item">
									<p>Bank Name:</p>
									<span>{invoiceData?.bank_name}</span>
								</div>
								<div className="invoice-list__item">
									<p>Bank Address:</p>
									<span>{invoiceData?.bank_address}</span>
								</div>
								<div className="invoice-list__item">
									<p>Account Number:</p>
									<span>{invoiceData?.account_number}</span>
								</div>
								<div className="invoice-list__item">
									<p>SWIFT / BIC:</p>
									<span>{invoiceData?.swift}</span>
								</div>
								<div className="invoice-list__item">
									<p>Transfer Reference:</p>
									<span style={{ overflowWrap: 'break-word' }}>
										{invoiceData?.transfer_reference}
									</span>
								</div>
								<div className="invoice-list__item invoice-list__item--type2 invoice-list__item--mb-0">
									<p>
										Please ensure you include the reference exactly as it appears above with no
										extra text. Failure to use the correct reference may result in delays crediting
										your deposit.
									</p>
								</div>
								{/* <div className="invoice-list__item invoice-list__item--type2">
									<p>IMPORTANT:</p>
									<span>{invoiceData?.important}</span>
								</div> */}
							</div>
						</div>
					</div>
					<div className="popup-footer">
						<div className="popup-submit popup-submit--type2 mt-20">
							<a href={invoiceFiles?.url_download} className="btn btn-primary btn--full">
								Download Instructions
							</a>
							<button type="button" onClick={closeModal} className="btn btn-outline btn-secondary">
								Close
							</button>
						</div>
					</div>
				</div>
			</div>
		</Popup>
	);
};

export default InvoiceGenerate;
