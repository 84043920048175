import { endpoint } from 'services/endpoint';
import { http } from 'services/http';
import { ICurrencyApi } from './types';
// ==========================================:

export const currency: ICurrencyApi = {
	getCurrency: () =>
		http.get(endpoint.currency.GET_CURRENCY).then((response) => {
			return response.data;
		}),

	getFees: () => http.get(endpoint.currency.GET_FEES).then(({ data }) => data),
};
